import revive_payload_client_PprFklG9ny from "/app/node_modules/.pnpm/nuxt@3.13.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.1_encoding@0.1.1_zcmfwbd7n2blovmkp2h5ldwxze/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_RbEHn1wYyn from "/app/node_modules/.pnpm/nuxt@3.13.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.1_encoding@0.1.1_zcmfwbd7n2blovmkp2h5ldwxze/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_w7re8Pks2H from "/app/node_modules/.pnpm/nuxt@3.13.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.1_encoding@0.1.1_zcmfwbd7n2blovmkp2h5ldwxze/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_xsccbJ3S0T from "/app/node_modules/.pnpm/nuxt@3.13.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.1_encoding@0.1.1_zcmfwbd7n2blovmkp2h5ldwxze/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Oi2MFEot8V from "/app/node_modules/.pnpm/nuxt@3.13.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.1_encoding@0.1.1_zcmfwbd7n2blovmkp2h5ldwxze/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_zmt9PnDkpM from "/app/node_modules/.pnpm/nuxt@3.13.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.1_encoding@0.1.1_zcmfwbd7n2blovmkp2h5ldwxze/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_2hKGuStkg1 from "/app/node_modules/.pnpm/nuxt@3.13.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.1_encoding@0.1.1_zcmfwbd7n2blovmkp2h5ldwxze/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_oaIf8xJpMc from "/app/node_modules/.pnpm/nuxt@3.13.0_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.1_encoding@0.1.1_zcmfwbd7n2blovmkp2h5ldwxze/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_iYCGKsovrQ from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_magicast@0.3.4_rollup@4.18.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_5iBdbppXH4 from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.4_rollup@4.18.0_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_uQvNeN1eig from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.4_rollup@4.18.0_vue@3.4.38_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
export default [
  revive_payload_client_PprFklG9ny,
  unhead_RbEHn1wYyn,
  router_w7re8Pks2H,
  payload_client_xsccbJ3S0T,
  navigation_repaint_client_Oi2MFEot8V,
  check_outdated_build_client_zmt9PnDkpM,
  chunk_reload_client_2hKGuStkg1,
  components_plugin_KR1HBZs4kY,
  prefetch_client_oaIf8xJpMc,
  plugin_client_iYCGKsovrQ,
  switch_locale_path_ssr_5iBdbppXH4,
  i18n_uQvNeN1eig
]