export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"msapplication-TileColor","content":"#0065bd"},{"name":"theme-color","content":"#ffffff"},{"name":"author","content":"OpenSource @ TUM e.V. partnering with TUM IT Management"},{"name":"copyright","content":"GNU General Public License v3.0. Images may be licensed differently."},{"name":"robots","content":"index, follow"},{"name":"rating","content":"safe for kids"},{"name":"og:url","content":"https://nav.tum.de"},{"name":"og:image:alt","content":"Navigatum Logo"},{"name":"og:image:width","content":"1200"},{"name":"og:image:height","content":"630"},{"name":"og:image:mime","content":"image/png"},{"name":"og:site_name","content":"NavigaTUM"}],"link":[{"rel":"apple-touch-icon","sizes":"180x180","href":"/favicons/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicons/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicons/favicon-16x16.png"},{"rel":"manifest","href":"/site.webmanifest"},{"rel":"mask-icon","href":"/favicons/safari-pinned-tab.svg","color":"#0065bd"}],"style":[],"script":[{"innerHTML":"{\n\"@context\": \"https://schema.org\",\n\"@type\": \"WebSite\",\n\"url\": \"https://nav.tum.de/\",\n\"potentialAction\": [\n  {\n    \"@type\": \"SearchAction\",\n    \"target\": {\n      \"@type\": \"EntryPoint\",\n      \"urlTemplate\": \"https://nav.tum.de/search?q={search_term_string}\"\n    },\n    \"query-input\": \"required name=search_term_string\"\n  }\n]\n}","type":"application/ld+json"},{"innerHTML":"{\n\"@context\": \"https://schema.org\",\n\"@type\": \"Organization\",\n\"url\": \"https://nav.tum.de/\",\n\"logo\": \"https://nav.tum.de/logos/org_logo.svg\"\n}","type":"application/ld+json"},{"innerHTML":"window.$plausible = [];"}],"noscript":[],"bodyAttrs":{"class":"bg-zinc-50"},"charset":"utf-8","viewport":"width=device-width, initial-scale=1"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"deep":false}

export const resetAsyncDataToUndefined = false

export const nuxtDefaultErrorValue = undefined

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false